<template>
  <div id="pc-container">
    <div id="pc-mask"></div>
    <div id="pc-main" class="center">
      <!-- left logo -->
      <div id="pc-left-logo" class="center">
        <a href="//trongeniusfriends.xyz" target="_blank" class="logo" style="margin-right: 48.5px">Home</a>
        <a
          href="https://apenft.io/collections/tron-genius-friends"
          target="_blank"
          class="logo market-logo center"
          style="margin-right: 24px"
          >Market</a
        >
      </div>
      <!-- right link -->
      <div id="pc-right-icon" class="center">
        <a href="https://twitter.com/GeniusFriends2" target="_blank"
          ><img style="width: 38.5px; margin-right: 16px" src="@/assets/pc/twitter@2x.png"
        /></a>
        <a href="https://t.me/TronGeniusFriends" target="_blank"
          ><img style="width: 38.5px" src="@/assets/pc/telegram 2@2x.png"
        /></a>
      </div>

      <div id="pc-center">
        <div id="pc-moto" class="center">
          <img src="@/assets/pc/animation.gif" style="width: 300px; height: 300px; margin-top: 0px" />
        </div>
        <div id="pc-center-right">
          <stage-23 />
        </div>
      </div>
      <div class="center" style="margin-top: 50px">
        <img src="@/assets/pc/tron@2x.png" style="width: 20px; margin-right: 5px" />
        <span style="font-family: hel-bd">1111 GENIUS FRIENDS OF YOURS ON TRON.</span>
      </div>

      <!-- bottom -->
      <div id="pc-bottom" class="center">
        <img src="@/assets/pc/trandape@2x.png" style="width: 303px" />
      </div>
    </div>
  </div>
</template>
<script>
import { countdownMixin } from '../mixin';
import Stage23 from './stage23.vue';

export default {
  name: 'mint-pc',
  mixins: [countdownMixin],
  components: {
    stage23: Stage23,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="less">
@font-face {
  font-family: hel-round;
  src: url('~@/assets/Helvetica-Rounded-LT-Std-Black.ttf');
}
@font-face {
  font-family: hel-bd;
  src: url('~@/assets/HelveticaRoundedLTStd-Bd.otf');
}
@font-face {
  font-family: hel-bdcn;
  src: url('~@/assets/HelveticaRoundedLTStd-BdCn.otf');
}
#pc-container {
  width: 100vw;
  min-width: 1080px;
  height: 100vh;
  background-image: url('~@/assets/pc/bg.png');
  background-size: cover;
  position: relative;
  font-family: hel-round;
}
#pc-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  // background: rgba(0, 0, 0, 1);
}
#pc-main {
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
#pc-left-logo {
  position: absolute;
  left: 40px;
  top: 25px;
  font-size: 21.5px;
  color: #fff;
  .logo {
    color: #fff;
    font-weight: bold;
    padding-top: 6px;
  }
  .market-logo {
    background: #fff;
    border-radius: 50px;
    width: 132px;
    height: 38.5px;
    color: #6d5fff;
    padding-top: 6px;
  }
}
#pc-right-icon {
  position: absolute;
  top: 25px;
  right: 40px;
}
#pc-center {
  display: flex;
  // padding-bottom: 20px;
  padding-top: 20px;
  #pc-moto {
  }
  #pc-center-right {
    width: 420px;
    margin-left: 15px;
    justify-content: flex-start;
  }
}
#pc-bottom {
  position: absolute;
  width: 100%;
  bottom: 35px;
  color: #9e9c9b;
  /* font-weight: normal; */
  font-size: 14px;
  letter-spacing: 3.5px;
}

#pc-set {
  position: absolute;
  bottom: 70px;
  border: 1px dashed;
  padding: 0 5px;
  font-size: 13px;
}
</style>
