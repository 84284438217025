<template>
  <div>
    <div>
      <p style="color: #fff; font-size: 16px; font-family: hel-bd">PUBLIC SALES ARE LIVE</p>
      <p style="font-size: 28px; margin-top: 10px">TRON GENIUS FRIENDS</p>
    </div>

    <div class="center" style="justify-content: flex-start; margin: 10px 0">
      <img src="@/assets/pc/tron 2@2x.png" style="width: 42px; margin-right: 8px" />
      <p style="white-space: nowrap">
        <span style="font-size: 28px; font-family: hel-bdcn">100TRX</span>
      </p>
    </div>

    <div class="mint-box" v-if="!isSoldOut">
      <!-- total available -->
      <!-- <div style="height: 80px; padding-top: 10px">
        <p style="font-size: 17px">
          Total Available:
          <span v-if="!isConnected">Connect your wallet to see</span>
          <span v-else>{{ max }}</span>
        </p>
        <p style="font-size: 12px; font-style: italic; margin-top: 5px; margin-bottom: 20px">
          Price per unit: 100TRX + Gas Fee
        </p>
      </div> -->
      <!-- num-select -->
      <div class="mint-select center">
        <div class="edit-icon center" :class="{ disabled: minusDisabled }" @click="minusMintNum">&#8722;</div>
        <div class="edit-input center" style="padding-top: 6px">{{ mintNum }}</div>
        <div class="edit-icon center" :class="{ disabled: addDisabled }" @click="addMintNum">&#43;</div>
      </div>
      <!-- confirm -->
      <div>
        <div class="mint-confirm center" v-if="!isConnected && !isConnecting" @click="connectWallet">
          CONNECT WALLET
        </div>
        <div class="mint-confirm center disabled" v-else-if="isConnecting">CONNECTING..</div>
        <div class="mint-confirm center" :class="{ disabled: !canMint }" v-else-if="!isMinting" @click="triggerMint">
          MINT
        </div>
        <div class="mint-confirm center disabled" v-else>MINTING..</div>
      </div>
    </div>

    <div class="mint-box-soldout center" v-else>
      <p style="font-size: 40px; font-weight: bold">SOLD OUT</p>
      <!-- <p style="font-size: 14px; font-weight: bold; margin-top: 5px">BUY ON APENET</p> -->
      <!-- <img src="@/assets/pc/sold-out-icon.png" id="soldout-icon" /> -->
    </div>
  </div>
</template>
<script>
import { walletMixin } from '../mixin.js';
export default {
  name: 'stage23',
  mixins: [walletMixin],
  props: {
    countdownArr: [],
    stage: Number,
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.countdown-box {
  width: 39px;
  height: 29px;
  background: #cd4743;
  border-radius: 3px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.countdown-divider {
  color: #cd4743;
  margin: 0 5px;
}
.mint-box {
  background-color: #fff;
  border-radius: 17px;
  text-align: center;
  color: #000;
  width: 320px;
  height: 150px;
  padding-top: 1px;
}
.mint-box-soldout {
  background-color: #fff;
  border-radius: 17px;
  width: 320px;
  height: 150px;
  text-align: center;
  color: #000;
  position: relative;
  padding-top: 1px;
  #soldout-icon {
    width: 60px;
    position: absolute;
    bottom: 10px;
    left: 100px;
  }
}
.mint-select {
  width: 190px;
  margin: 28px auto 0 auto;
  .edit-icon {
    width: 38px;
    height: 38px;
    background-color: #ff7fdb;
    font-size: 37.5px;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    font-family: hel-bdcn;
    // padding-bottom: 3px;
    padding-top: 4px;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .edit-input {
    font-size: 30px;
    font-family: hel-bdcn;
    font-weight: bold;
    color: #000;
    width: 106px;
    text-align: center;
  }
}
.mint-confirm {
  width: 275px;
  height: 47px;
  border-radius: 44px;
  background-color: #ff7fdb;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 13px;
  font-size: 20px;
  padding-top: 4px;
  &.disabled {
    opacity: 0.5;
  }
}
</style>
